import SleeperData from "../components/sleeperseason/SleeperData";

function AnalyticsPage(){

    return (
        <div>
            <SleeperData />
        </div>
    )
}

export default AnalyticsPage;